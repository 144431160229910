import Button from '@components/Button';
import { withStyles } from '@material-ui/core/styles';
import Table from '@components/Table';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { apiClient } from '@services/api';
import { useHistory } from "react-router-dom";
import BreadCrumbs from '@components/Breadcrumbs';
import Modal from '@components/Modal';
import id from 'date-fns/locale/id/index';
import Moment from 'react-moment';

const permissions = ['administrador'];
const linkbackend = process.env.REACT_APP_BASE_IMAGE_URL;

const BrownSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
    '&$checked + $track': {
      backgroundColor: `${process.env.REACT_APP_ADMIN_COLOR}`,
    },
  },
  checked: {},
  track: {},
})(Switch);

const RowSwitch = (params) => {
  const [status, setStatus] = useState(params.status);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setLoading(true);

    apiClient.httpInstance.put(`/template_messages/${params.id}/status`, {
      status: !status,
    })
      .then((response) => {
        setLoading(false);
        setStatus(response.data);
      })
      .catch((error) => console.log(error));
  };
  return (<div className="flex items-center">
    <CircularProgress style={{ display: loading ? 'block' : 'none' }} size={15} />
    <BrownSwitch
      checked={status}
      onChange={(handleChange)}
      name="checkedA"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    /></div>);
}

const Index = (props) => {
  document.title = `Mensagens - ${process.env.REACT_APP_NAME}`
  const history = useHistory();
  const [rows] = useState([]);
  const [q, setQ] = useState("");
  const [license_type, setLicenseType] = useState("all")
  const [openModalMessages, setOpenModalMessages] = useState(false)
  const [messageDetails, setMessageDetails] = useState([])
  const [selectedMessage, setSelectedMessage] = useState({})
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)
  const [menuOpen, setMenuOpen] = useState(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const verifyMessages = (receiver, template, single, deleteM) => {
    setLoading(true)

    const single_message = single ? single : false
    const customer_receiver = receiver ? receiver : false
    const template_id = template ? template : false
    const deleteMessages = deleteM ? 'sim' : false

    apiClient.httpInstance.post(`/central/send_messages`, {
      delete_message: deleteMessages,
      template_id,
      single_message,
      customer_receiver
    }).then(response => {
      console.log(response)
      if (response.status === 200) {
      }
      setMenuOpen(null)
      setLoading(false)
      setRefresh(true);
      setRefresh(false);
    }).catch(function (response) {
      setLoading(false)
      setRefresh(true);
      setRefresh(false);
    });
  }

  //Oculta menu lateral
  useEffect(() => {
    props.setShowMenu(false)
  }, []);

  const changePageDetails = (e, id) => {
    e.preventDefault();
    history.push(`mensagens/detalhe/${id}`)
  }
  const showMessageDetails = (e, send) => {
    console.log('send', send)
    setSelectedMessage(send)
    setMessageDetails([])
    setLoading(true)

    const date = new Date(send.created_at)
    const created_at = (new Date(date.setTime(date.getTime() - 3 * 60 * 60 * 1000)).toJSON('pt-BR', { timeZone: 'America/Sao_Paulo' }))

    apiClient.httpInstance.get(`/central/message_details?template_id=${send.template_id}&user_sender=${send.user_sender}&created_at=${created_at}`, {})
      .then(async (response) => {
        // console.log('response.data', response.data)
        const messages = response.data.data
        for (var i = 0; i < messages.length; i++) {
          messages[i].checkContact = await checkContact(messages[i].customer_receiver)
        }

        setMessageDetails(messages);
        setLoading(false)
      })
      .catch((error) => { setLoading(false); console.log(error) });
    setOpenModalMessages(true)
  }

  const checkContact = async (customer_receiver) => {
    const resp = await apiClient.httpInstance.get(`/central/check_contact?customer_receiver=${customer_receiver}`)
    if (resp?.data?.name != null && resp?.data?.name != undefined && resp?.data?.name != '') {
      return true
    } else {
      return false
    }
  }

  const bradecrumbsConfig = [
    {
      title: 'Início',
    }
  ]

  const onChooseModal = (e, row) => {
    if (e == 1) {
      //send
      verifyMessages(false, row?.template_id, true) // set false to send to all customers of the message row
    } else if (e == 2) {
      //view
      showMessageDetails(e, row)
    } else {
      //delete
      verifyMessages(false, row?.id, true, true)
    }

  }

  const onOpen = (id) => {
    const item = document.getElementById(`meuItem${id}`);
    const posicao = item.getBoundingClientRect();

    console.log(posicao);
    setPosition({ x: posicao?.x, y: posicao?.top, bottom: posicao?.bottom });
  }

  return <>
    <div className="px-7">
      <div className="flex items-center justify-between mt-2 text-2xl text-blue mb-3">
        <div className="flex items-center">
          <Icon className="mr-2">
            accessibility
          </Icon>
          <span>Lista de mensagens</span>
        </div>
        <div className="flex items-center">
          <Button
            textcolor="#fff"
            padding="6px 15px"
            margintop="0px"
            marginright="10px"
            width="200"
            style={{ minWidth: 150, height: 35 }}
            onClick={() => verifyMessages()}
            customcolor={props.theme.color}
          >
            {loading && !openModalMessages ? <CircularProgress color='inherit' size={15} /> : 'Realizar Envios'}
          </Button>
          {permissions.includes(props?.theme?.permission) && <Button
            textcolor="#fff"
            padding="6px 15px"
            margintop="0px"
            width="200"
            onClick={() => history.push('/admin/central/mensagens/cadastro')}
            customcolor={props.theme.color}
          >
            <Icon className="mr-2">add</Icon>
            Novo
          </Button>}
        </div>
      </div>
      <Table
        refresh={refresh}
        path="/template_messages"
        onRowClick={null}
        columns={[
          {
            headerName: 'Template',
            headerClassName: 'super-app-theme--header',
            field: 'template_name',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full" onClick={(e) => showMessageDetails(e, row)} >
                {row.template_name}
              </div>);
            }
          },
          {
            headerName: 'Enviado em',
            headerClassName: 'super-app-theme--header',
            field: 'date_send',
            flex: 0.5,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full z-10" >
                <p>{row?.date_send ? <Moment format="DD/MM/YYYY" date={row?.date_send} /> : '--/--/--'}</p>
              </div>);
            }
          },
          {
            headerName: 'Enviado por',
            headerClassName: 'super-app-theme--header',
            field: 'user_name',
            flex: 1,
            renderCell: (params) => {
              const row = params.row;
              return (<div className="w-full h-full" onClick={(e) => showMessageDetails(e, row)} >
                {row.user_name}
              </div>);
            }
          },
          {
            headerName: 'Envio',
            headerClassName: 'super-app-theme--header',
            field: 'sent',
            flex: 0.4,
            renderCell: (params) => {

              const row = params.row;

              return (<div className="w-full h-full" id={`meuItem${row?.id}`} >
                <div onClick={(e) => {setMenuOpen(row?.id); onOpen(row?.id) }}>opções</div>
                {menuOpen === row?.id &&
                  <div style={{ marginTop: (position?.bottom < 410 && position?.y < 360 ) ? 80 : -60, marginBottom: (position?.bottom > 280 && position?.y > 350 ) ? 280 : 0 }} >
                    <ModalChoose onChoose={(e) => onChooseModal(e, row)} onClose={() => setMenuOpen(null)} />
                  </div>}
              </div>);
            }
          },
        ]}
        rows={rows}
      />
      <Modal open={openModalMessages} style={{ width: '90%', maxWidth: '650px' }}>
        <div className="flex mt-4">
          <p>Envios:</p>
        </div>
        {loading && <center className="mt-4"><CircularProgress size={20} className="w-100" /></center>}
        {messageDetails.map((message) => (
          <div className="flex flex-direction-row mt-4 ml-2">
            <p style={{ width: '95%' }}>- {message.customer_name}  </p>
            <p>{message.date_send ? <Moment format="DD/MM/YYYY" date={message.date_send} /> : '--/--/--'}</p>
          </div>
        )
        )}

        <br />
        <p>Template: {selectedMessage.template_name}</p> <br />
        {selectedMessage.url_link && <a href={linkbackend + '/' + selectedMessage.url_link} className='mr-4' target="_blank">Link</a>}
        {selectedMessage.url_video && <a href={linkbackend + '/' + selectedMessage.url_video} className='mr-4' target="_blank">Video</a>}
        {selectedMessage.url_document && <a href={linkbackend + '/' + selectedMessage.url_document} className='mr-4' target="_blank">Documento</a>}
        {selectedMessage.url_image && <a href={linkbackend + '/' + selectedMessage.url_image} className='mr-4' target="_blank">Imagem</a>}

        < div className="justify-center mt-4 md:flex w-100" >
          <Button
            textcolor="#fff"
            padding="10px 15px"
            margintop="0px"
            width="300px"
            onClick={(ev) => setOpenModalMessages(false)}
          >
            Fechar
          </Button>
        </div>
      </Modal>
    </div>
  </>
}

const ModalChoose = ({ onChoose, onClose }) => {
  const options = [
    {
      id: '1',
      titulo: 'Enviar',
    },
    {
      id: '2',
      titulo: 'Visualizar'
    },
    {
      id: '3',
      titulo: 'Excluir'
    }
  ]
  return (
    <div
      className="flex flex-col items-center bg-white border border-gray-200 rounded-lg absolute"
      style={{ width: '180px', zIndex: '99999999', right: '10px', marginTop: - 100 }}>
      <div
        className='absolute h-6 w-6 right-0 rounded-full flex flex-col items-center justify-center'
        style={{ top: '0px', backgroundColor: '#172B4D' }}
        onClick={() => onClose()}>
        <Icon style={{ cursor: 'pointer', fontSize: 16, color: '#fff' }}>close</Icon>
      </div>

      {options && options?.map((option, index) => {
        return <div key={'bt-' + index + ''} className='hover:bg-lightgray w-full text-center' onClick={(e) => onChoose(option?.id) }><a className="">{option.titulo}</a></div>
      })}
    </div>
  )
}

export default Index;
