import './App.css';
import Auth from './layouts/auth';
import Admin from './layouts/admin';
import VoucherLink from './layouts/voucherLink';
import VoucherLinkPresale from './layouts/voucherLink/presale';
import VoucherLinkExit from './layouts/voucherLink/exit';
import ExitsDriverAndGuide from './layouts/exitsDriverAndGuide';
import SignatureReservation from './layouts/signatureReservation';
import SignatureShow from './layouts/signatureReservation/show';
import Partner from './layouts/partner';
import React from 'react';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = props => {
  /* limpa sessão caso o tempo de expiração esteja ultrapassado */
  let now = new Date()
  if (JSON.parse(localStorage.getItem('user'))?.expiration < now) {
    localStorage.setItem('user', JSON.stringify({}));
    window.location = '/login';
  }

  return (
    <>
      <ToastContainer autoClose={5000} />
      <div className="App">
        <Switch>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route exact path="/voucher-presale/:sale_id/:token">
            <VoucherLinkPresale />
          </Route>
          <Route exact path="/voucher/:sale_id/:token">
            <VoucherLink />
          </Route>
          <Route exact path="/voucher-exit/:sale_id/:token">
            <VoucherLinkExit />
          </Route>
          <Route exact path="/vp/:sale_id">
            <VoucherLinkPresale />
          </Route>
          <Route exact path="/vs/:sale_id">
            <VoucherLink />
          </Route>
          <Route exact path="/ve/:sale_id">
            <VoucherLinkExit />
          </Route>
          <Route path="/saidas/:type_user/:access_token/:permission">
            <ExitsDriverAndGuide />
          </Route>
          <Route path="/validar-assinatura/:sale_id">
            <SignatureReservation />
          </Route>
          <Route path="/sign/:sale_id">
            <SignatureShow />
          </Route>
          <Route path="/parceiro/:access_token">
            <Partner />
          </Route>
          <Redirect exact from="/" to="/login" />
          <Route path="/">
            <Auth />
          </Route>
        </Switch>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(App);
