import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import Button from '@components/Button';
import AdminConfig from '@config/admin';
import { apiClient } from '@services/api';
import { dateLocale, slugfy, translator } from '@utils/helpers';
import '@css/Admin/index.scss';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams, useRouteMatch
} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import Header from './partials/Header';

import { toast } from "react-toastify";

class Content extends React.Component {
  render() {
    const props = this.props;
    const idiom = (props.voucher.customerData.language || 'pt').toUpperCase();
    const payment_status = {
      accomplished: 'Pago',
      pending: 'Pendente',
      partial: 'Parcial'
    };

    const language = {
      pt: 'Português',
      en: 'Inglês',
      es: 'Espanhol'
    };

    const formatHour = time => {
      if (time) {
        const hourArrar = time.split(":")
        const hour = new Date().setHours(hourArrar[0], hourArrar[1]);
        return format(hour, "HH:mm")
      }
      return null
    }

    return <>
      <div id='desktop' className="p-10 bg-white border-1 text-blue hidden sm:hidden md:hidden lg:block xl:block" style={{ borderRadius: '10px 10px 0px 0px', maxWidth: '1350px' }}>
        <>
          <div className="flex justify-space-around">
            <div className="flex flex-col text-center mr-3">
              <div>
                <img src={props.voucher.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.voucher.company.logo}` : null} className="self-center" alt="" style={{ width: '120px' }} />
              </div>
            </div>
            <div className="flex flex-row justify-between space-y-1" style={{ width: '100%' }}>
              <div className="text-left">
                <div className="flex flex-col">
                  <p>{props.voucher.company.name}</p>
                </div>
              </div>
              <div className="text-right mt-5 pt-5" style={{ fontSize: '0.7rem' }}>
                <p>{translator(idiom, 'voucher', 'rut')}: {props.voucher.company.rut}</p>
                <p>{translator(idiom, 'voucher', 'address')}: {props.voucher.company.address} - {props.voucher.company.district} - {props.voucher.company.state} - {props.voucher.company.country}</p>
                <p>{translator(idiom, 'voucher', 'phone')}: {props.voucher.company.phone}</p>
              </div>
            </div>
          </div>
          <div className="my-5 border-t border-b border-solid border-lightgray"></div>

          <div className="flex flex-row text-left justify-between">
            <div className="flex flex-col text-left mr-6">
              <div className="flex my-5 text-xl">
                Informações da assinatura
              </div>
              <div className="flex flex-col text-left">
                <p><span className="font-bold">{translator(idiom, 'voucher', 'customer_name')}:</span> {props.voucher.reservationData.customer_name}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'document')}:</span> {props.voucher.customerData.document}</p>
                <p><span className="font-bold">{translator(idiom, 'voucher', 'email')}:</span> {props.voucher?.customerData?.email}</p>
                <p><span className="font-bold">HASH do documento original(SHA256):</span> {props.voucher?.signatures?.public_key}</p>
              </div>
            </div>
          </div>
          <div className="my-5 border-t border-b border-solid border-lightgray"></div>

          <div className="flex flex-row justify-between space-y-1" style={{ width: '100%' }}>
            <div className="text-left mr-5 mt-1" style={{ fontSize: '0.7rem' }}>
              <p>{dateLocale(props.voucher?.signature_date)}</p>
            </div>
            <div className="text-left" style={{ fontSize: '0.7rem' }}>
              <p>{props.voucher.reservationData.customer_name} assinou como contratante. Ponto de autenticação email {props.voucher?.customerData?.email} (via token). CPF informado {props.voucher.customerData.document}. IP: {props.voucher?.signature_ip}</p>
            </div>
          </div>
        </>
      </div>

      {/* mobile */}
      <div className="p-8 bg-white border-1 text-blue block xs:block sm:block md:block lg:hidden xl:hidden" style={{ borderRadius: '10px 10px 0px 0px', fontSize: '14px', display: 'none' }}>
        <>
          <div className="flex justify-space-around">
            <div className="flex flex-col text-center mr-3">
              <div>
                <img src={props.voucher.company.logo ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${props.voucher.company.logo}` : null} className="self-center" alt="" style={{ width: '80px' }} />
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <div className="text-left">
                <div className="flex flex-col">
                  <p>{props.voucher.company.name}</p>
                </div>
              </div>
              <div className="text-right mt-5 pt-5" style={{ fontSize: '0.7rem' }}>
                <p>{translator(idiom, 'voucher', 'rut')}: {props.voucher.company.rut}</p>
                <p>{translator(idiom, 'voucher', 'address')}: {props.voucher.company.address} - {props.voucher.company.district} - {props.voucher.company.state} - {props.voucher.company.country}</p>
                <p>{translator(idiom, 'voucher', 'phone')}: {props.voucher.company.phone}</p>
              </div>
            </div>
          </div>
          <div className="my-5 border-t border-b border-solid border-lightgray"></div>

          <div className="flex my-5 text-lg ">
            Informações da assinatura
          </div>
          <div className="flex flex-col space-y-1 text-left">
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'customer_name')}
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.reservationData.customer_name}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'document')}
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher.customerData.document}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  {translator(idiom, 'voucher', 'email')}
                </div>
                <div className="flex flex-col items-end justify-center flex-1 space-y-1 ">
                  {props.voucher?.customerData?.email}
                </div>
              </div>
            </div>
            <div className="flex flex-col" style={{ width: '100%' }}>
              <div className="flex md:space-y-0 md:justify-between md:flex-row" style={{ width: '100%' }}>
                <div className="flex flex-col items-start flex-1 space-y-1 font-bold">
                  HASH do documento original(SHA256)
                </div>
              </div>
              {props.voucher?.signatures?.public_key}
            </div>
          </div>

          <div className="my-5 border-t border-b border-solid border-lightgray"></div>
          <div className="flex flex-col mt-4">

            <div className="flex flex-row justify-between space-y-1" style={{ width: '100%' }}>
              <div className="text-left mr-5 mt-1" style={{ fontSize: '0.7rem' }}>
                <p><Moment format="DD/MM/YYYY HH:MM" date={props.voucher?.signature_date} />h</p>
              </div>
              <div className="text-left" style={{ fontSize: '0.7rem' }}>
                <p>{props.voucher.reservationData.customer_name} assinou como contratante. Ponto de autenticação email {props.voucher?.customerData?.email} (via token). CPF informado {props.voucher.customerData.document}. IP: {props.voucher?.signature_ip}</p>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  }
}

const VoucherLinkExit = props => {
  let { path } = useRouteMatch();
  const history = useHistory();
  const componentRef = useRef()
  const { sale_id, token } = useParams();
  const [voucher, setVoucher] = useState({});
  const [loading, setLoading] = useState(true);
  const [sendingVoucher, setSendingVoucher] = useState(false);
  let url = token ? `/customer/get_sale_voucher/${sale_id}?access_token=${token}` : `/customer/get_sale_voucher/${sale_id}?sale_id=${sale_id}`;
  const [success, setSuccess] = useState(false);
  const sendVoucherUrl = `sales/${sale_id}/send_voucher`;
  const [openModal, setOpenModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [checked, setChecked] = useState(true);
  const [loadingSignature, setLoadingSignature] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [successCode, setSuccessCode] = useState(false);
  const [ip, setIP] = useState('');
  const [code, setCode] = useState('');
  const [codeConfirm, setCodeConfirm] = useState('');
  const [error, setError] = React.useState(false);

  const getVoucher = url => {
    apiClient.httpInstance.get(url).then(response => {
      if (response.status === 200) {
        setVoucher(response.data);
        setLoading(false);
      }
    }).catch(function (response) {
      //history.push('/');
    });
  }

  const sendVoucher = () => {
    setSendingVoucher(true);

    apiClient.httpInstance.post(sendVoucherUrl).then(response => {

      const isStatusOk = response.status == 200 || response.status === 200;

      if (isStatusOk) {

        setSuccess(true);
        setSendingVoucher(false);

        toast.success(`Voucher enviado com sucesso!`, {
          position: "top-right",
        });

        setTimeout(() => {
          setSuccess(false);
        }, 2000);

        return;
      }

      setSendingVoucher(false);
      setSuccess(false);

      toast.error(`Erro ao enviar voucher para e-mail`, {
        position: "top-right",
      });

    })
      .catch(error => {
        setSendingVoucher(false);
        setSuccess(false);

        toast.error(`Erro ao enviar voucher para e-mail`, {
          position: "top-right",
        });
      });
  }

  const changeCodeConfirm = (value) => {
    setCodeConfirm(value)
  }

  const sendSignCode = (customer_id, customer_email) => {

    setLoadingCode(true)

    apiClient.httpInstance.post(`sales/${sale_id}/send_code_signature/${customer_id}`, { "email": customer_email }).then(response => {

      const isStatusOk = response.status == 200 || response.status === 200;

      if (isStatusOk) {
        getVoucher(url);
        setLoadingCode(false);
        setSuccessCode(true);
      }

      if (!isStatusOk) {

        setSuccessCode(false);
        setLoadingCode(false);

        toast.error(`Erro ao enviar código de assinatura para e-mail: ${customer_email}`, {
          position: "top-right",
        });
      }

    })
      .catch(error => {
        setSuccessCode(false);
        setLoadingCode(false);

        toast.error(`Erro ao enviar código de assinatura para e-mail: ${customer_email}`, {
          position: "top-right",
        });
      });
  }

  const confirmSignature = () => {
    setError(false)

    if (!codeConfirm) {
      setLoadingSignature(false)
      setError(true)
      return
    }
    
    setLoadingSignature(true)
    apiClient.httpInstance.post(`sales/${sale_id}/sale_signature`, {
      customer_ip: ip,
      customer_hash: codeConfirm
    }).then(response => {
      if (response.status === 200) {
        getVoucher(url);
        setOpenModal(false)
        setSuccessModal(true)
      }
    }).catch(function (response) {
      setLoadingSignature(false)
      setError(true)
    });
    ;
  }

  useEffect(() => {
    getVoucher(url);
  }, [url]);

  let theme = AdminConfig.themes[slugfy(props?.user?.data?.permission)] || AdminConfig.themes.logistica;

  return <Switch>
    <React.Fragment>
      <div className="flex flex-col admin">
        <Header theme={theme} />
        <div className="flex flex-row bg-lightgray">
          <Route path={`${path}`}>
            <div className="w-full flex justify-center mx-0 mb-20 pt-14 md:mx-28">
              <>
                {!loading && <div className="px-7">
                  <div className="flex items-center justify-between mt-2 text-2xl text-blue flex-wrap">
                    <div className="w-96 md:w-0">Assinatura</div>
                    <div className="space-x-3 w-100">
                      <ReactToPrint
                        trigger={() => <Button
                          textcolor="#FFF"
                          padding="6px 15px"
                          width="200"
                        >
                          Visualizar PDF
                        </Button>
                        }
                        content={() => componentRef.current}
                        onBeforeGetContent={() => {
                          document.getElementById('desktop').classList.remove('hidden')
                          document.getElementById('desktop').classList.remove('sm:hidden')
                          document.getElementById('desktop').classList.remove('md:hidden')
                        }}
                        onAfterPrint={() => {
                          document.getElementById('desktop').classList.add('hidden')
                          document.getElementById('desktop').classList.add('sm:hidden')
                          document.getElementById('desktop').classList.add('md:hidden')
                        }}
                        onPrintError={() => {
                          document.getElementById('desktop').classList.add('hidden')
                          document.getElementById('desktop').classList.add('sm:hidden')
                          document.getElementById('desktop').classList.add('md:hidden')
                        }}
                        pageStyle="@page { size: 10in 13.5in; }"
                      />
                    </div>
                  </div>
                  <div className="my-4 d-flex">
                  </div>
                  <Content
                    {...props}
                    voucher={voucher}
                    ref={componentRef}
                  />
                </div>}
              </>
            </div>
          </Route>
        </div>
      </div>
    </React.Fragment>
  </Switch>
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
  mapStateToProps
)(VoucherLinkExit);